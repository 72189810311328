<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="teding" colspan="2">
            <img class="teding-img" src="@/assets/image/teding.jpg" />
          </td>
          <td class="customize-title1" colspan="6">
            PAY SLIP 中东公司员工工资条
          </td>
          <td class="text-center">Date <br />申请日期</td>
          <td class="text-center">{{ TableDate | tableDateFrom }}</td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">Company Name 公司名称</td>
          <td class="text-center" colspan="6"></td>
          <td class="text-center">Sponsor <br />Name 签证类型</td>
          <td class="text-center"></td>
        </tr>
        <tr>
          <td class="text-center">
            Employee ID <br />
            员工编号
          </td>
          <td class="text-center"></td>
          <td class="text-center">
            Name in English<br />
            姓名
          </td>
          <td class="text-center"></td>
          <td class="text-center">
            Nationality<br />
            国籍
          </td>
          <td class="text-center"></td>
          <td class="text-center" colspan="2">
            Temporary Residence ID <br />
            NationalID 暂住证号
          </td>
          <td class="text-center" colspan="2"></td>
        </tr>
        <tr>
          <td class="text-center">
            Project Name<br />
            项目部信息
          </td>
          <td class="text-center"></td>
          <td class="text-center">
            Sub-project Name<br />
            分项目部信息
          </td>
          <td class="text-center"></td>
          <td class="text-center">
            Working Unit<br />
            用工单位
          </td>
          <td class="text-center"></td>
          <td class="text-center">
            Job Position<br />
            岗位名称
          </td>
          <td class="text-center"></td>
          <td class="text-center">
            Pay Slip for month<br />
            支付月份
          </td>
          <td class="text-center"></td>
        </tr>
        <tr>
          <td class="text-center" colspan="7">Net Payment 工资实发(SAR)</td>
          <td class="text-center" rowspan="2" colspan="3">
            Bonus 奖金发放 (SAR)
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="4">SALARY & ALLOWANCE 工资及补贴</td>
          <td class="text-center" colspan="3">DEDUCTIONS 工资扣减</td>
        </tr>
        <tr>
          <td class="text-right" colspan="3">Basic Salary <br />基本工资</td>
          <td class="text-center text-red"></td>
          <td class="text-right" colspan="2">
            Other Deduchtion <br />其他扣减
          </td>
          <td class="text-center text-red"></td>
          <td class="text-right" colspan="2">
            Appraisal Bonus-C1 <br /> 管理考核奖
          </td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right" colspan="3">
            Housing Allowance <br />住房补贴
          </td>
          <td class="text-center text-red"></td>
          <td class="text-right" colspan="2">
            Employee GOSI Contribution<br />
            GOSI扣减
          </td>
          <td class="text-center text-red"></td>
          <td class="text-right" colspan="2">
            Appraisal Bonus-C2 <br />月预支奖金
          </td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right" colspan="3">
            Transportation Allowance<br />
            交通通讯补贴
          </td>
          <td class="text-center text-red"></td>
          <td class="text-right" colspan="2"></td>
          <td class="text-center text-red"></td>
          <td class="text-right" colspan="2">
            Appraisal Bonus-C3<br />
            月度奖
          </td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right" colspan="3">
            Other/Performance Appraisal Bonus <br />其他/月度考核工资
          </td>
          <td class="text-center text-red"></td>
          <td class="text-right" colspan="2"></td>
          <td class="text-center text-red"></td>
          <td class="text-right" colspan="2">
            Appraisal Bonus-C4 <br />超额奖
          </td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right" colspan="3">
            Overtime Payment <br />加班加点工资
          </td>
          <td class="text-center text-red"></td>
          <td class="text-right" colspan="2"></td>
          <td class="text-center text-red"></td>
          <td class="text-right" colspan="2">
            Engineerring Bonus <br />设计工日奖
          </td>
          <td class="text-center text-red"></td>
        </tr>

        <tr v-for="(item, index) in list" :key="item.name">
          <td class="text-right" colspan="3"></td>
          <td class="text-center text-red"></td>
          <td class="text-right" colspan="2"></td>
          <td class="text-center text-red"></td>
          <td class="text-right" colspan="2">
            {{ item.nameEN }} <br />{{ item.name }}
          </td>
          <td class="text-center text-red">{{ item.value }}</td>
        </tr>
        <tr>
          <td class="text-right" colspan="3">Summary 小计</td>
          <td class="text-center text-red"></td>
          <td class="text-right" colspan="2">Summary 小计</td>
          <td class="text-center text-red"></td>
          <td class="text-right" colspan="2">Summary 小计</td>
          <td class="text-center text-red"></td>
        </tr>

        <tr>
          <td class="text-left" colspan="10">
            <p>
              Statement: The salary details for the current month are as
              outlined above. Should you have any objections to the contents,
              please ensure that they are submitted by the 10th day of the
              following month. Failure to raise objections within the specified
              period shall be deemed as acceptance and acknowledgment of the
              salary details for the current month.
            </p>
            <p class="p-title">
              申明：本月工资明细如上所示。若对明细内容有任何异议，请务必于次月10日前提出。若未在规定期限内提出异议，则视为对本月工资明细的认可与接受。
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { getNewDate } from "@/utils/date";
import {
  allCostCenters,
  getProjectAll,
  getCompanyAll,
  getDepartmentAll,
  getCostCategoryTree,
} from "@/api/user";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
  },
  data() {
    return {
      TableDate: null,
      list: [
        {
          nameEN: "Appraisal Bonus-C5",
          name: "效益工资（月税）",
          value: "",
        },
        {
          nameEN: "Appraisal Bonus-C6",
          name: "项目完工兑现奖",
          value: "",
        },
        {
          nameEN: "Appraisal Bonus-C7",
          name: "年终兑现奖",
          value: "",
        },
        {
          nameEN: "Excess Contribution Award",
          name: "超额贡献奖",
          value: "",
        },
        {
          nameEN: "Telecom Subsidy",
          name: "通讯补贴",
          value: "",
        },
        {
          nameEN: "Epidemic Subsidy",
          name: "疫情补贴",
          value: "",
        },
        {
          nameEN: "Milestone Completion Bonus",
          name: "赶工奖",
          value: "",
        },
        {
          nameEN: "Safety Management Bonus",
          name: "安全奖",
          value: "",
        },
        {
          nameEN: "Market Development Award 1",
          name: "任务承揽奖",
          value: "",
        },
        {
          nameEN: "Milestone Completion Bonus",
          name: "控制点奖",
          value: "",
        },
        {
          nameEN: "Quanlity Control Bonus",
          name: "质量奖",
          value: "",
        },
        {
          nameEN: "Other Bonus",
          name: "单项奖",
          value: "",
        },
        {
          nameEN: "Other Bonus",
          name: "其他专项奖",
          value: "",
        },
        {
          nameEN: "Excellence Award Bonus",
          name: "先进奖励",
          value: "",
        },
        {
          nameEN: "Bonus for Work Order Work",
          name: "签证奖",
          value: "",
        },
        {
          nameEN: "Royalty",
          name: "宣传报道奖励",
          value: "",
        },
        {
          nameEN: "Bonus of Headquarter General Manager",
          name: "总经理奖励",
          value: "",
        },
        {
          nameEN: "Other Bonus",
          name: "其他收益（奖金）",
          value: "",
        },
        {
          nameEN: "Department Bonus of Headquarter",
          name: "国内直接发放",
          value: "",
        },
      ],
    };
  },

  methods: {
    geteditData(data1, data2, data3, params) {
      console.log("params", params);
      this.projectList = data1;
      this.companyList = data2;
      this.departmentList = data3;
      this.TableDate = params.TableDate;
      this.ListerDept = params.ListerDept;
      this.CostCategoryName = params.CostCategoryName;
      this.Amount = params.Amount;
      this.Duration = params.Duration;
      this.ITMSANO = params.ITMSANO;
      this.BankInformation = params.BankInformation;
      this.BankAccountName = params.BankAccountName;
      this.BankName = params.BankName;
      this.BankAccount = params.BankAccount;
      this.ID = params.ID;
      this.KeyID = params.KeyID;
      this.HROfficer = params.HROfficer;
      this.HRSuperintendent = params.HRSuperintendent;
      this.ExecutiveDirector = params.ExecutiveDirector;
      this.ApplicationNumber = params.ApplicationNumber;
      this.CompanyNameofBeneficiary = params.CompanyNameofBeneficiary;
      this.CRNumberofBeneficiary = params.CRNumberofBeneficiary;
      this.BankCode = params.BankCode;
      this.Note = params.Note;
      this.CostCategory = params.CostCategoryIDs.split(",");
      this.CostCategory = this.CostCategory.map(Number);
      this.CostAllocationDetails.forEach((_, index, Array) => {
        if (params.Details[index]) {
          params.Details[index].CostAllocationType =
            params.Details[index].TypeID;
          Array[index] = JSON.parse(JSON.stringify(params.Details[index]));
          Array[index].CostCenterList = [];
          Array[index].SubProjeList = [];
          console.log("11111", this.CostAllocationDetails);
          allCostCenters({
            projectId: Array[index].ProjectID,
            companyId: Array[index].CompanyID,
            deptId: Array[index].DepartmentID,
          }).then((res) => {
            if (res.status === 200) {
              Array[index].CostCenterList = res.response;
              if (res.response.length === 1) {
                Array[index].CostCenterID = res.response[0].Id;
                Array[index].CostCenterCode = res.response[0].Code;
              }
            }
          });
          this.getProjectsChildren(index, Array[index].ProjectID);
        }
      });
      this.CostAllocationDetails = this.CostAllocationDetails.concat();
      console.log("11111", this.CostAllocationDetails);
    },
  },
  created() {
    this.TableDate = getNewDate();
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });

    getCostCategoryTree().then((res) => {
      if (res.status === 200) {
        this.tree = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .teding {
    max-width: 100%;
  }
  .teding-img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    max-width: 200px;
  }
  .customize-title1 {
    font-size: 30px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      min-width: 70px;
      padding: 10px 5px;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 10px 5px;
      .el-select {
        max-width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 10px 5px;
    }
    .text-red {
      color: red;
    }
    .text-left {
      text-align: left;
      font-size: 13px;
      padding: 10px 5px;
      line-height: 18px;
      .p-title {
        margin-top: 16px;
      }
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}
</style>