var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"customize-main"},[_c('table',{staticClass:"customize-form",attrs:{"border":"0","cellpadding":"0","cellspacing":"0"}},[_c('tbody',[_c('tr',[_vm._m(0),_c('td',{staticClass:"customize-title1",attrs:{"colspan":"6"}},[_vm._v(" 中石化南京工程中东公司中国籍员工工资条 ")]),_vm._m(1),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("tableDateFrom")(_vm.TableDate)))])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_vm._m(21),_vm._m(22),_vm._m(23),_vm._m(24),_vm._m(25),_vm._m(26),_vm._m(27),_vm._m(28),_vm._m(29),_vm._m(30),_vm._m(31),_vm._m(32)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"teding",attrs:{"colspan":"2"}},[_c('img',{staticClass:"teding-img",attrs:{"src":require("@/assets/image/teding.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_vm._v("Date "),_c('br'),_vm._v("申请日期")])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"2"}},[_vm._v("Company Name 公司名称")]),_c('td',{staticClass:"text-center",attrs:{"colspan":"6"}}),_c('td',{staticClass:"text-center"},[_vm._v("Sponsor "),_c('br'),_vm._v("Name 签证类型")]),_c('td',{staticClass:"text-center"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-center"},[_vm._v("员工编号")]),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"},[_vm._v("姓名")]),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"},[_vm._v("国籍")]),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"},[_vm._v("暂住证号")]),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"},[_vm._v("支付月份")]),_c('td',{staticClass:"text-center"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-center"},[_vm._v("项目部信息")]),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"},[_vm._v("分项目部信息")]),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"},[_vm._v("用工单位")]),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"},[_vm._v("岗位名称")]),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"},[_vm._v("用工形式")]),_c('td',{staticClass:"text-center"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"5"}},[_vm._v("沙特工资发额(RMB)")]),_c('td',{staticClass:"text-center",attrs:{"colspan":"5"}},[_vm._v("沙特奖金发放额(RMB)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"3"}},[_vm._v("工资发放明细")]),_c('td',{staticClass:"text-center",attrs:{"colspan":"2"}},[_vm._v("工资扣减明细")]),_c('td',{staticClass:"text-center",attrs:{"colspan":"3"}},[_vm._v("奖金发放明细")]),_c('td',{staticClass:"text-center",attrs:{"colspan":"2"}},[_vm._v("奖金扣减明细")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("岗位补贴/定额单价")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"},[_vm._v("其他扣减")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("管理考核奖")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"},[_vm._v("其他扣减")]),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("地区补贴")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("月预支奖金")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("家庭补贴")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("月度奖")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("月绩效补贴/月超额奖")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("超额奖")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("野外工作补贴")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("设计工日奖")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("超额利润奖励")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("效益工资(月税)")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("风险补贴")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("项目完工兑现奖")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("假日加班补贴")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("年终兑现奖")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("节日加班补贴")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("超额贡献奖")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("加点补贴")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("通讯补贴")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("其他收益")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("疫情补贴")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("赶工奖")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("安全奖")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("任务承揽奖")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("控制点奖")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("质量奖")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("单项奖")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("其他专项奖")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("先进奖励")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("签证奖")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("宣传报道奖励")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("总经理奖励")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("其他收益(奖金)")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("国内直接发放")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("Summary 小计")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"},[_vm._v("Summary 小计")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("Summary 小计")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right"},[_vm._v("Summary 小计")]),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-left",attrs:{"colspan":"10"}},[_c('p',{staticClass:"p-title"},[_vm._v(" 申明：本月工资明细如上所示。若对明细内容有任何异议，请务必于次月10日前提出。若未在规定期限内提出异议，则视为对本月工资明细的认可与接受。 ")])])])
}]

export { render, staticRenderFns }