<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="teding" colspan="2">
            <img class="teding-img" src="@/assets/image/teding.jpg" />
          </td>
          <td class="customize-title1" colspan="6">
            中石化南京工程中东公司中国籍员工工资条
          </td>
          <td class="text-center">Date <br />申请日期</td>
          <td class="text-center">{{ TableDate | tableDateFrom }}</td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">Company Name 公司名称</td>
          <td class="text-center" colspan="6"></td>
          <td class="text-center">Sponsor <br />Name 签证类型</td>
          <td class="text-center"></td>
        </tr>
        <tr>
          <td class="text-center">员工编号</td>
          <td class="text-center"></td>
          <td class="text-center">姓名</td>
          <td class="text-center"></td>
          <td class="text-center">国籍</td>
          <td class="text-center"></td>
          <td class="text-center">暂住证号</td>
          <td class="text-center"></td>
          <td class="text-center">支付月份</td>
          <td class="text-center"></td>
        </tr>
        <tr>
          <td class="text-center">项目部信息</td>
          <td class="text-center"></td>
          <td class="text-center">分项目部信息</td>
          <td class="text-center"></td>
          <td class="text-center">用工单位</td>
          <td class="text-center"></td>
          <td class="text-center">岗位名称</td>
          <td class="text-center"></td>
          <td class="text-center">用工形式</td>
          <td class="text-center"></td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">沙特工资发额(RMB)</td>
          <td class="text-center text-red" colspan="2">0</td>
          <td class="text-center" colspan="2">沙特奖金发放额(RMB)</td>
          <td class="text-center text-red" colspan="2">0</td>
          <td class="text-center">国内清缴(RMB)</td>
          <td class="text-center text-red">0</td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">工资发放明细(RMB)</td>
          <td class="text-center" colspan="2">工资扣减明细(RMB)</td>
          <td class="text-center" colspan="2">奖金发放明细(RMB)</td>
          <td class="text-center" colspan="2">奖金扣减明细(RMB)</td>
          <td class="text-center" colspan="2">社保两金个税清缴明细(RMB)</td>
        </tr>
        <tr>
          <td class="text-right">基本薪酬</td>
          <td class="text-center text-red"></td>
          <td class="text-right">护理假扣款</td>
          <td class="text-center text-red"></td>
          <td class="text-right">管理考核奖</td>
          <td class="text-center text-red"></td>
          <td class="text-right">其他扣减</td>
          <td class="text-center text-red"></td>
          <td class="text-right">住房公积金个人</td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right">基本薪酬补发</td>
          <td class="text-center text-red"></td>
          <td class="text-right">病假扣款</td>
          <td class="text-center text-red"></td>
          <td class="text-right">月预支奖金</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right">养老保险</td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right">保留薪资</td>
          <td class="text-center text-red"></td>
          <td class="text-right">事假扣款</td>
          <td class="text-center text-red"></td>
          <td class="text-right">月度奖</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right">失业保险</td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right">组(伙)长津贴</td>
          <td class="text-center text-red"></td>
          <td class="text-right">旷工扣款</td>
          <td class="text-center text-red"></td>
          <td class="text-right">超额奖</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right">医疗保险</td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right">高技术(技能)津贴</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right">设计工日奖</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right">年金个人</td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right">房租补贴</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right">效益工资(月税)</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right">个税</td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right">住房补贴</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right">项目完工兑现奖</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right">休假疗养补贴</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right">年终兑现奖</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right">物业补贴</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right">超额贡献奖</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right">海外工作补贴</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right">通讯补贴</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right">艰苦补贴</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right">疫情补贴</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right">休假补贴</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right">赶工奖</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right">其他补贴</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right">安全奖</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right">任务承揽奖</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right">控制点奖</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right">质量奖</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right">单项奖</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right">其他专项奖</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right">先进奖励</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right">签证奖</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right">宣传报道奖励</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right">总经理奖励</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right">其他收益(奖金)</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right">国内直接发放</td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
          <td class="text-right"></td>
          <td class="text-center text-red"></td>
        </tr>
        <tr>
          <td class="text-right">Summary 小计</td>
          <td class="text-center text-red"></td>
          <td class="text-right">Summary 小计</td>
          <td class="text-center text-red"></td>
          <td class="text-right">Summary 小计</td>
          <td class="text-center text-red"></td>
          <td class="text-right">Summary 小计</td>
          <td class="text-center text-red"></td>
          <td class="text-right">Summary 小计</td>
          <td class="text-center text-red"></td>
        </tr>

        <tr>
          <td class="text-left" colspan="10">
            <p class="p-title">
              申明：本月工资明细如上所示。若对明细内容有任何异议，请务必于次月10日前提出。若未在规定期限内提出异议，则视为对本月工资明细的认可与接受。
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { getNewDate } from "@/utils/date";
import {
  allCostCenters,
  getProjectAll,
  getCompanyAll,
  getDepartmentAll,
  getCostCategoryTree,
} from "@/api/user";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
  },
  data() {
    return {
      TableDate: null,
    };
  },

  methods: {
    geteditData(data1, data2, data3, params) {
      console.log("params", params);
      this.projectList = data1;
      this.companyList = data2;
      this.departmentList = data3;
      this.TableDate = params.TableDate;
      this.ListerDept = params.ListerDept;
      this.CostCategoryName = params.CostCategoryName;
      this.Amount = params.Amount;
      this.Duration = params.Duration;
      this.ITMSANO = params.ITMSANO;
      this.BankInformation = params.BankInformation;
      this.BankAccountName = params.BankAccountName;
      this.BankName = params.BankName;
      this.BankAccount = params.BankAccount;
      this.ID = params.ID;
      this.KeyID = params.KeyID;
      this.HROfficer = params.HROfficer;
      this.HRSuperintendent = params.HRSuperintendent;
      this.ExecutiveDirector = params.ExecutiveDirector;
      this.ApplicationNumber = params.ApplicationNumber;
      this.CompanyNameofBeneficiary = params.CompanyNameofBeneficiary;
      this.CRNumberofBeneficiary = params.CRNumberofBeneficiary;
      this.BankCode = params.BankCode;
      this.Note = params.Note;
      this.CostCategory = params.CostCategoryIDs.split(",");
      this.CostCategory = this.CostCategory.map(Number);
      this.CostAllocationDetails.forEach((_, index, Array) => {
        if (params.Details[index]) {
          params.Details[index].CostAllocationType =
            params.Details[index].TypeID;
          Array[index] = JSON.parse(JSON.stringify(params.Details[index]));
          Array[index].CostCenterList = [];
          Array[index].SubProjeList = [];
          console.log("11111", this.CostAllocationDetails);
          allCostCenters({
            projectId: Array[index].ProjectID,
            companyId: Array[index].CompanyID,
            deptId: Array[index].DepartmentID,
          }).then((res) => {
            if (res.status === 200) {
              Array[index].CostCenterList = res.response;
              if (res.response.length === 1) {
                Array[index].CostCenterID = res.response[0].Id;
                Array[index].CostCenterCode = res.response[0].Code;
              }
            }
          });
          this.getProjectsChildren(index, Array[index].ProjectID);
        }
      });
      this.CostAllocationDetails = this.CostAllocationDetails.concat();
      console.log("11111", this.CostAllocationDetails);
    },
  },
  created() {
    this.TableDate = getNewDate();
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });

    getCostCategoryTree().then((res) => {
      if (res.status === 200) {
        this.tree = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .teding {
    max-width: 100%;
  }
  .teding-img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    max-width: 200px;
  }
  .customize-title1 {
    font-size: 30px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      min-width: 70px;
      padding: 10px 5px;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 10px 5px;
      .el-select {
        max-width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 10px 5px;
    }
    .text-red {
      color: red;
    }
    .text-left {
      text-align: left;
      font-size: 13px;
      padding: 10px 5px;
      line-height: 18px;
      .p-title {
        margin-top: 16px;
        margin-bottom: 40px;
      }
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}
</style>