var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"customize-main"},[_c('table',{staticClass:"customize-form",attrs:{"border":"0","cellpadding":"0","cellspacing":"0"}},[_c('tbody',[_c('tr',[_vm._m(0),_c('td',{staticClass:"customize-title1",attrs:{"colspan":"6"}},[_vm._v(" PAY SLIP 中东公司员工工资条 ")]),_vm._m(1),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("tableDateFrom")(_vm.TableDate)))])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._l((_vm.list),function(item,index){return _c('tr',{key:item.name},[_c('td',{staticClass:"text-right",attrs:{"colspan":"3"}}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(item.nameEN)+" "),_c('br'),_vm._v(_vm._s(item.name)+" ")]),_c('td',{staticClass:"text-center text-red"},[_vm._v(_vm._s(item.value))])])}),_vm._m(12),_vm._m(13)],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"teding",attrs:{"colspan":"2"}},[_c('img',{staticClass:"teding-img",attrs:{"src":require("@/assets/image/teding.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_vm._v("Date "),_c('br'),_vm._v("申请日期")])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"2"}},[_vm._v("Company Name 公司名称")]),_c('td',{staticClass:"text-center",attrs:{"colspan":"6"}}),_c('td',{staticClass:"text-center"},[_vm._v("Sponsor "),_c('br'),_vm._v("Name 签证类型")]),_c('td',{staticClass:"text-center"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(" Employee ID "),_c('br'),_vm._v(" 员工编号 ")]),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"},[_vm._v(" Name in English"),_c('br'),_vm._v(" 姓名 ")]),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"},[_vm._v(" Nationality"),_c('br'),_vm._v(" 国籍 ")]),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center",attrs:{"colspan":"2"}},[_vm._v(" Temporary Residence ID "),_c('br'),_vm._v(" NationalID 暂住证号 ")]),_c('td',{staticClass:"text-center",attrs:{"colspan":"2"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(" Project Name"),_c('br'),_vm._v(" 项目部信息 ")]),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"},[_vm._v(" Sub-project Name"),_c('br'),_vm._v(" 分项目部信息 ")]),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"},[_vm._v(" Working Unit"),_c('br'),_vm._v(" 用工单位 ")]),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"},[_vm._v(" Job Position"),_c('br'),_vm._v(" 岗位名称 ")]),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"},[_vm._v(" Pay Slip for month"),_c('br'),_vm._v(" 支付月份 ")]),_c('td',{staticClass:"text-center"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"7"}},[_vm._v("Net Payment 工资实发(SAR)")]),_c('td',{staticClass:"text-center",attrs:{"rowspan":"2","colspan":"3"}},[_vm._v(" Bonus 奖金发放 (SAR) ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"4"}},[_vm._v("SALARY & ALLOWANCE 工资及补贴")]),_c('td',{staticClass:"text-center",attrs:{"colspan":"3"}},[_vm._v("DEDUCTIONS 工资扣减")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"3"}},[_vm._v("Basic Salary "),_c('br'),_vm._v("基本工资")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v(" Other Deduchtion "),_c('br'),_vm._v("其他扣减 ")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v(" Appraisal Bonus-C1 "),_c('br'),_vm._v(" 管理考核奖 ")]),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"3"}},[_vm._v(" Housing Allowance "),_c('br'),_vm._v("住房补贴 ")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v(" Employee GOSI Contribution"),_c('br'),_vm._v(" GOSI扣减 ")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v(" Appraisal Bonus-C2 "),_c('br'),_vm._v("月预支奖金 ")]),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"3"}},[_vm._v(" Transportation Allowance"),_c('br'),_vm._v(" 交通通讯补贴 ")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v(" Appraisal Bonus-C3"),_c('br'),_vm._v(" 月度奖 ")]),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"3"}},[_vm._v(" Other/Performance Appraisal Bonus "),_c('br'),_vm._v("其他/月度考核工资 ")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v(" Appraisal Bonus-C4 "),_c('br'),_vm._v("超额奖 ")]),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"3"}},[_vm._v(" Overtime Payment "),_c('br'),_vm._v("加班加点工资 ")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}}),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v(" Engineerring Bonus "),_c('br'),_vm._v("设计工日奖 ")]),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"3"}},[_vm._v("Summary 小计")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("Summary 小计")]),_c('td',{staticClass:"text-center text-red"}),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_vm._v("Summary 小计")]),_c('td',{staticClass:"text-center text-red"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-left",attrs:{"colspan":"10"}},[_c('p',[_vm._v(" Statement: The salary details for the current month are as outlined above. Should you have any objections to the contents, please ensure that they are submitted by the 10th day of the following month. Failure to raise objections within the specified period shall be deemed as acceptance and acknowledgment of the salary details for the current month. ")]),_c('p',{staticClass:"p-title"},[_vm._v(" 申明：本月工资明细如上所示。若对明细内容有任何异议，请务必于次月10日前提出。若未在规定期限内提出异议，则视为对本月工资明细的认可与接受。 ")])])])
}]

export { render, staticRenderFns }